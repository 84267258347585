import React from 'react';
import './nav.scss';

const Nav = () => {
    return (
        <nav>
            <div className="nav-logo">
                <a href="#home">Valentin.dev</a>
            </div>

            <ul className="nav-links">
                <li className="link"><a href="#about">À propos</a></li>
                <li className="link"><a href="#profile">Mon profil</a></li>
                <li className="link"><a href="#works">Mes travaux</a></li>
            </ul>
        </nav>
    );
};

export default Nav;
