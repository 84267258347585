import React from 'react';
import './profile.scss';

import cvFile from '../../assets/CV_Dumas_Valentin_Developpeur_JavaScript_React.pdf';

import book from '../../assets/icons/book-solid.svg';
import htmlCssIcon from '../../assets/icons/code-solid.svg';
import DebugIcon from '../../assets/icons/bug-solid.svg';
import gitIcon from '../../assets/icons/code-pull-request-solid.svg';
import frontIcon from '../../assets/icons/laptop-code-solid.svg';
import backIcon from '../../assets/icons/circle-nodes-solid.svg';
import jsIcon from '../../assets/icons/gears-solid.svg';
import nextIcon from '../../assets/icons/forward-solid.svg';

const Profile = () => {
    const profiles = [
        {
            title: "Mes formations chez Openclassrooms",
            icon: book,
            details: [
                "2024 : Développeur web",
                "Septembre 2024 : Développeur d'application JavaScript React ou Développeur d'application Java en alternance",
                "Septembre 2026 : Développeur Full-Stack - Java et Angular en alternance"
            ]
        },
        {
            title: "Intégration web avec HTML - CSS",
            icon: htmlCssIcon,
            details: [
                "Intégrer du contenu conformément à une maquette avec HTML et CSS",
                "Implémenter une interface responsive avec HTML et CSS"
            ]
        },
        {
            title: "Développement web avec JavaScript",
            icon: jsIcon,
            details: [
                "Récupérer les données utilisateurs dans le JavaScript via des formulaires",
                "Manipuler les éléments du DOM avec JavaScript",
                "Gérer les événements utilisateurs avec JavaScript"
            ]
        },
        {
            title: "Gestion de projet et outils de développeurs",
            icon: gitIcon,
            details: [
                "Versionner son projet avec Git et Github",
                "Mettre en place une méthode de veille technologique",
                "Rédiger les spécifications techniques d'un projet web à partir de besoins fonctionnels",
            ]
        },
        {
            title: "Optimisation et debug",
            icon: DebugIcon,
            details: [
                "Optimiser les performances d’un site web",
                "Débugger un site web grâce aux Chrome DevTools",
                "Rédiger un cahier de recette pour tester un site"
            ]
        },
        {
            title: "Développement front-end avec React et Sass",
            icon: frontIcon,
            details: [
                "Configurer la navigation entre les pages de l'application avec React Router",
                "Initialiser une application avec Create React App",
                "Développer des éléments de l'interface d'un site web grâce à des composants React"
            ]
        },
        {
            title: "Développement back-end avec NodeJS, Express",
            icon: backIcon,
            details: [
                "Implémenter un modèle logique de données conformément à la réglementation",
                "Mettre en œuvre des opérations CRUD de manière sécurisée",
                "Stocker des données de manière sécurisée dans une base de donnée MongoDB"
            ]
        },
        {
            title: "Prochaines compétences à développer",
            icon: nextIcon,
            details: [
                "Développer des compétences de web designer",
                "Approfondir mes compétences sur d'autres Frameworks Front-End Avancés : Angular / Vue.js / Tailwinds",
                "Approfondir mes compétences en développement de Back-End Avancé"
            ]
        },
        // Ajouts d'autres profils ici
    ];

    return (
        <section className="profile-container" id="profile">
            <h2 className="header">Mon profil</h2>
            <div className="features">
                {profiles.map((profile, index) => (
                    <div className="card" key={index}>
                        <div className='titleIcon'>
                            <img src={profile.icon} alt={`${profile.title} icon`} className="icon" />
                            <h4>{profile.title}</h4>
                        </div>
                        <ul>
                            {profile.details.map((detail, index) => (
                                <li key={index}>{detail}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <a href={cvFile} download className="btn-download-cv">
                Télécharger mon CV
            </a>
        </section>
    );
};

export default Profile;
