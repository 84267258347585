import React, { useState } from 'react';
import Modal from '../Modal/modal';
import './works.scss';

// Importation des images des projets
// P1
import projectCover1 from '../../assets/Projets/P1/projectCover1.png';
import projectImage1 from '../../assets/Projets/P1/Booki_desktop.png';
import projectImage2 from '../../assets/Projets/P1/Booki_tablet.png';
import projectImage3 from '../../assets/Projets/P1/Booki_mobile.png';

// P2
import projectCover2 from '../../assets/Projets/P2/projectCover2.png';
import projectImage4 from '../../assets/Projets/P2/projectImage4.png';
import projectImage5 from '../../assets/Projets/P2/projectImage5.png';
import projectImage6 from '../../assets/Projets/P2/projectImage6.png';
import projectImage7 from '../../assets/Projets/P2/projectImage7.png';
import projectImage8 from '../../assets/Projets/P2/projectImage8.png';

// P3
import projectCover3 from '../../assets/Projets/P3/projectCover3.png';
import projectImage9 from '../../assets/Projets/P3/projectImage9.png';

// P4
import projectCover4 from '../../assets/Projets/P4/projectCover4.png';
import projectImage10 from '../../assets/Projets/P4/projectImage10.png';
import projectImage11 from '../../assets/Projets/P4/projectImage11.png';
import projectImage12 from '../../assets/Projets/P4/projectImage12.png';

// P5
import projectCover5 from '../../assets/Projets/P5/projectCover5.png';
import projectImage13 from '../../assets/Projets/P5/projectImage13.png';
import projectImage14 from '../../assets/Projets/P5/projectImage14.png';
import projectImage15 from '../../assets/Projets/P5/projectImage15.png';
import projectImage16 from '../../assets/Projets/P5/projectImage16.png';

const Works = () => {
    const [projects] = useState([
        {
            id: 1,
            title: 'Booki, Agence de Voyage',
            cover: projectCover1,
            images: [projectImage1, projectImage2, projectImage3],
            description: 'Ce projet à été réalisé dans le cadre de ma formation Développeur Web. Dans ce projet, j\'ai développé la page d\'accueil d\'une agence de voyage, en utilisant HTML et CSS, basée sur des maquettes Figma pour différentes tailles d\'écrans (mobile, tablette et desktop). Ce projet m\'a permis de mettre en pratique les principes de design responsive, en veillant à ce que la page soit fonctionnelle et esthétiquement plaisante sur tous les appareils.',
            issues: [['Problématique : Adapter le design à différentes résolutions d\'écran tout en respectant les spécifications précises des maquettes Figma.', 'Solution : J\'ai utilisé les média queries pour ajuster les éléments de la page en fonction de la taille de l\'écran, en testant rigoureusement le rendu sur plusieurs dispositifs pour assurer une expérience utilisateur cohérente.'],
                    ['Problématique : Garantir que le code écrit soit propre et maintenable, sans dépendre des outils de code automatique de Figma.', 'Solution : J\'ai écrit manuellement le code HTML et CSS, ce qui m\'a permis de mieux comprendre et de contrôler le comportement du layout et du styling, et d\'optimiser le code pour de meilleures performances.']],
            skills: ['Design Responsive : J\'ai appris à créer des interfaces qui s\'adaptent dynamiquement à la taille de l\'écran de l\'utilisateur, une compétence essentielle pour tout développeur front-end moderne.', 'Intégration précise de maquettes : J\'ai développé ma capacité à transformer des designs Figma en code HTML et CSS fonctionnel, respectant scrupuleusement les spécifications de design.', 'Utilisation de Git et GitHub : J\'ai renforcé mes compétences en gestion de versions, ce qui me permet de mieux organiser mon code et de collaborer avec d\'autres développeurs.', 'Configuration d\'un environnement de développement : J\'ai établi une routine de développement efficace, en configurant mon environnement pour optimiser mon flux de travail.'],
            link: 'https://github.com/Nano315/booki-code'
        },
        {
            id: 2,
            title: 'Sophie Bluel, Portfolio',
            cover: projectCover2,
            images: [projectImage4, projectImage5, projectImage6, projectImage7, projectImage8],
            description: 'Ce projet à été réalisé dans le cadre de ma formation Développeur Web. Il consistait à créer une page web dynamique pour le site internet d\'une architecte d\'intérieur, en utilisant JavaScript pour manipuler le DOM et gérer les interactions utilisateurs. J\'ai également intégré des fonctionnalités de connexion et de gestion de contenu via une API, en développant une modale pour uploader de nouveaux médias.',
            issues: [['Problématique : Assurer une interaction fluide sur la page sans ralentissements malgré les dynamiques complexes et les requêtes API.', 'Solution : J\'ai optimisé le code JavaScript en asynchrone pour gérer les requêtes de manière efficace, et j\'ai utilisé des techniques de debouncing pour les événements fréquents comme le scrolling ou le redimensionnement de fenêtre.'],
                     ['Problématique : Intégrer de manière sécurisée une fonctionnalité de connexion pour l\'administrateur.', 'Solution : J\'ai mis en œuvre des mesures de sécurité pour le formulaire de connexion, en utilisant HTTPS pour les requêtes et en stockant les données de manière sécurisée.']],
            skills: ['Gestion des événements utilisateurs avec JavaScript : J\'ai acquis une expertise dans l\'écriture de scripts qui répondent efficacement aux interactions des utilisateurs.', 'Manipulation des éléments du DOM avec JavaScript : J\'ai développé la capacité de modifier dynamiquement le contenu et la structure de la page en fonction des actions de l\'utilisateur.', 'Communication avec des APIs : J\'ai appris à récupérer et à envoyer des informations à un serveur back-end, une compétence indispensable pour le développement de fonctionnalités interactives sur les sites web.'],
            link: 'https://github.com/Nano315/Portfolio-architecte-sophie-bluel-master'
        },
        {
            id: 3,
            title: 'Nina Carducci, Portfolio',
            cover: projectCover3,
            images: [projectImage9],
            description: 'Ce projet à été réalisé dans le cadre de ma formation Développeur Web. Dans ce projet, j\'ai travaillé à optimiser le référencement, la performance, et l\'accessibilité d\'un site web de photographe. J\'ai utilisé des outils comme Lighthouse et Wave pour identifier et diagnostiquer les problèmes, puis j\'ai implémenté des solutions pour améliorer la vitesse de chargement et la conformité SEO.',
            issues: [['Problématique : Problèmes de chargement lents et de faible score SEO dus à un code non optimisé.', 'Solution : J\'ai révisé et optimisé le HTML, CSS, et JavaScript, réduit le poids des images et utilisé le lazy loading pour améliorer les temps de chargement et les scores de performance.'],
                     ['Problématique : Accessibilité limitée pour les utilisateurs avec des besoins spécifiques.', 'Solution : J\'ai appliqué les meilleures pratiques d\'accessibilité, ajusté les contrastes de couleurs, ajouté des textes alternatifs aux images et assuré la navigation au clavier sur tout le site.']],
            skills: ['Optimisation des performances du site web : J\'ai amélioré les temps de chargement et les scores de performance en utilisant des techniques avancées de front-end.', 'Débogage avec Chrome DevTools : J\'ai développé ma capacité à identifier et corriger rapidement les bugs grâce à une utilisation intensive des DevTools.', 'Rédaction d\'un cahier de recette : J\'ai créé un rapport détaillé des améliorations apportées, incluant des captures d\'écran avant et après les optimisations, fournissant une preuve concrète des améliorations.'],
            link: 'https://github.com/Nano315/P5-Nina-Carducci'
        },
        {
            id: 4,
            title: 'Kasa, Location Immobilière',
            cover: projectCover4,
            images: [projectImage10, projectImage11, projectImage12],
            description: 'Ce projet à été réalisé dans le cadre de ma formation Développeur Web. Ce projet a impliqué le développement du front-end d\'une application de location immobilière en utilisant React et React Router pour offrir une expérience utilisateur fluide et moderne. J\'ai configuré la navigation entre les pages avec React Router et utilisé des données simulées pour rendre les interactions plus réalistes, le tout en suivant des maquettes précises.',
            issues: [['Problématique : Maintenir un état cohérent de l\'application lors de la navigation entre les pages.', 'Solution : J\'ai utilisé React Router pour gérer le routage et j\'ai implémenté une gestion d\'état localisée pour préserver l\'état des composants à travers les différentes vues.'],
                     ['Problématique : Optimiser l\'expérience utilisateur avec des transitions fluides et réactives.', 'Solution : J\'ai appliqué des animations CSS et intégré SASS pour créer des styles dynamiques et réactifs, améliorant ainsi la réactivité et l\'esthétique de l\'application.']],
            skills: ['Développement avec React : J\'ai approfondi mes compétences dans la création de composants React et la gestion de leur état, essentiel pour les applications web modernes.', 'Utilisation de React Router : J\'ai maîtrisé la navigation entre les pages dans une application SPA, ce qui est crucial pour la création d\'expériences utilisateur sans interruption.', 'Animation CSS avec SASS : J\'ai utilisé SASS pour structurer efficacement les feuilles de style et implémenter des animations complexes pour enrichir l\'interaction utilisateur.'],
            link: 'https://github.com/Nano315/kasa'
        },
        {
            id: 5,
            title: 'Mon Vieux Grimoire, Site de Notation de Livres',
            cover: projectCover5,
            images: [projectImage13, projectImage14, projectImage15, projectImage16],
            description: 'Ce projet consistait à développer le back-end d\'un site de notation de livres en utilisant Express et MongoDB. J\'ai créé un serveur capable de gérer des opérations CRUD sécurisées pour les livres et leurs notations, tout en implémentant un système d\'authentification pour les utilisateurs. J\'ai aussi optimisé la gestion des images et mis en place une logique de calcul des notes moyennes.',
            issues: [['Problématique : Assurer la sécurité des données utilisateurs tout en permettant des opérations CRUD flexibles et efficaces.', 'Solution : J\'ai utilisé des pratiques sécurisées de codage, notamment l\'utilisation de JWT pour l\'authentification et le chiffrement des données sensibles avec bcrypt.'],
                     ['Problématique : Optimiser la gestion des images pour réduire l\'empreinte écologique et améliorer les performances du site.', 'Solution : J\'ai implémenté des techniques de compression d\'images et utilisé des formats d\'images efficaces pour réduire la charge du serveur et les temps de réponse.']],
            skills: ['Développement avec Node.js et Express : J\'ai approfondi ma maîtrise de la création de serveurs web et de la gestion de la logique serveur.', 'Manipulation de bases de données avec MongoDB : J\'ai développé des modèles de données complexes avec Mongoose et implémenté des opérations CRUD sécurisées.', 'Sécurité des données et authentification : J\'ai acquis des compétences en sécurisation des applications web, en implémentant des mécanismes d\'authentification et en protégeant les données des utilisateurs.'],
            link: 'https://github.com/Nano315/P7-Mon-Vieux-Grimoire'
        }        
        // ...autres projets
    ]);

    const [selectedProject, setSelectedProject] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleCardClick = (project) => {
        setSelectedProject(project);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <section className="works-container" id="works">
            <h2 className="works-header">Mes travaux</h2>
            <div className="project-cards">
                {projects.map((project) => (
                    <div className="project-card" key={project.id} onClick={() => handleCardClick(project)}>
                        <img src={project.cover} alt={`Couverture du ${project.title}`} className="project-cover" />
                        <div className="project-info">
                            <h3>{project.title}</h3>
                            <p>Voir plus...</p>
                        </div>
                    </div>
                ))}
            </div>
            {selectedProject && <Modal project={selectedProject} isOpen={modalIsOpen} onClose={closeModal} />}
        </section>
    );
};

export default Works;
