import React from 'react';
import Nav from '../../components/Nav/nav';
import Banner from '../../components/Banner/banner';
import About from '../../components/About/about';
import Profile from '../../components/Profile/profile';
import Works from '../../components/Works/works';
import Footer from '../../components/Footer/footer';

const HomePage = () => {
    return (
        <>
            <Nav />
            <Banner />
            <About/>
            <Profile/>
            <Works/>
            <Footer/>
        </>
    );
};

export default HomePage;