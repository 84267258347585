import React from 'react';
import './banner.scss';
import headerImage from '../../assets/contact-img.svg';

const Banner = () => {
    return (
        <header className="container">
            <div className="content">
                <span className="blur"></span>
                <span className="blur"></span>
                <h1>Bonjour !<br />
                    Je suis <span>Valentin Dumas</span>,<br />
                    développeur web</h1>
            </div>
            <div className="image">
                <img src={headerImage} alt="Header" />
            </div>
            <div className="work-link">
                <a href="#works">
                    <p>Voir mon travail</p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className='arrow'>
                        <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                </a>
            </div>
        </header>
    );
};

export default Banner;
