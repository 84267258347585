import React from 'react';
import './about.scss';

const About = () => {
    return (
        <section className="about" id="about">
            <h2>À propos</h2>
            <p>
                <b>Passionné</b> de développement web depuis le collège, j'ai cultivé mon engouement pour la technologie et la création numérique avec l'ambition de devenir <b>développeur fullstack</b>. Mon objectif est d'allier <b>expertise technique</b> et <b>vision créative</b> pour concevoir des solutions web innovantes et performantes.
            </p>
        </section>
    );
};

export default About;
