import React, { useEffect } from 'react';
import './modal.scss';

const Modal = ({ project, isOpen, onClose }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [isOpen]);

    if (!isOpen) return null;

    const handleClickOutside = (event) => {
        if (event.target.id === "modal-background") onClose();
    };

    return (
        <div id="modal-background" className="modal-background" onClick={handleClickOutside}>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2>{project.title}</h2>

                <h3>Description du projet :</h3>
                <p>{project.description}</p>

                <h3>Problématiques rencontrées et solutions :</h3>
                {project.issues.map(issue => (
                    <div key={issue[0]}>
                        <p>{issue[0]}</p>
                        <p>{issue[1]}</p>
                    </div>
                ))}

                <h3>Compétences acquises :</h3>
                <ul>
                    {project.skills.map(skill => <li key={skill}>{skill}</li>)}
                </ul>

                <h3><a href={project.link} target="_blank" rel="noopener noreferrer">Voir le code du projet</a></h3>

                <h3>Images du projet :</h3>
                <div className="project-images">
                    {project.images.map((image, index) => <img key={index} src={image} alt={`Project detail ${index + 1}`} />)}
                </div>
            </div>
        </div>
    );
};

export default Modal;
